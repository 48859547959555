import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SuiDatepickerModule } from 'ng2-semantic-ui';
import { Observable } from 'rxjs/Observable';

import { FilesService } from '../../services/files.service';
import { FoldersService } from '../../services/folders.service';
import { RequestsService } from '../../services/requests.service';
import { LoginService } from '../../services/login.service';
import { SharedEventsService } from '../../services/sharedEvents.service';

import { Constants } from '../../../constants/constants';

import { Folder } from '../../../models/folder';
import { AccessRequest } from '../../../models/accessRequest';
import { File } from '../../../models/file';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    public admin: boolean;
    public folders: Array<Folder>;
    private accessRequests: Array<AccessRequest>;
    private files: Array<File> = [];
    public user;
    public accessModalVisible: boolean;
    public dataLoading: boolean;

    constructor(
        private _foldersService: FoldersService,
        private _requestsService: RequestsService,
        private _loginService: LoginService,
        private _filesService: FilesService,
        private _sharedEventsService: SharedEventsService,
        private _router: Router
    ) { }

    public ngOnInit() {
        this.admin = this.setAdminStatus();

        this._sharedEventsService.dataChangeEmitted.subscribe(
            loading => {
                this.dataLoading = loading ? true : false;
            }
        );

        this._foldersService.getFolders().subscribe(
            (response: Array<Folder>) => {
                this.folders = response;

                if (this.folders.length) {
                    // get all available files, then sort into folders
                    this.getAllFiles();
                    // this.folders.forEach(folder => {
                    //     this.getFilesByFolder(folder.FolderName);
                    // });
                }
            },
            error => {
                console.log('get folders', error);
            });

        this._requestsService.getRequests().subscribe(
            (response: Array<AccessRequest>) => {
                if (response.length) {
                    response = response.map(request => {
                        const accessFolders = JSON.parse(request.FolderAccess);
                        request.FolderAccess = accessFolders;
                        return request;
                    });

                    this.accessRequests = response;
                }
            },
            err => {
                console.log('get access requests error:', err);
            }
        );
    }

    private getAllFiles() {
        this._filesService.getAllFiles().subscribe(
            files => {
                this.files = files;
                this._sharedEventsService.emitDataChange(false);
            },
            err => {
                console.log('get all files error: ', err);
                this._sharedEventsService.emitDataChange(false);
            }
        );
    }

    private getFilesByFolder(folderName) {
        this._filesService.getFiles(folderName).subscribe(
            response => {
                // make sure folder's files aren't already in list to prevent duplicates!
                this.files = this.files.filter(fileObj => {
                    if (fileObj.folder !== folderName) {
                        return true;
                    } else {
                        return false;
                    }
                });

                // update files list!
                if (response && response.length) {
                    this.files = this.files.concat(response);
                    // figure out how to get rid of duplicates!!!!!
                }
                this._sharedEventsService.emitDataChange(false);
            },
            err => {
                console.log('get files error:', err);
                this._sharedEventsService.emitDataChange(false);
            }
        );
    }

    private refreshFoldersList() {
        this._foldersService.getFolders().subscribe(
            (response: Array<Folder>) => {
                this.folders = response;
            },
            error => {
                console.log('Error from get folders: ', error);
            });
    }

    public refreshAccessRequests() {
        this._requestsService.getRequests().subscribe(
            (response: Array<AccessRequest>) => {
                if (response.length) {
                    response = response.map(request => {
                        let accessFolders = JSON.parse(request.FolderAccess);
                        request.FolderAccess = accessFolders;
                        return request;
                    });
                }
                this.accessRequests = response;
            },
            err => {
                console.log('Error from get access requests: ', err);
            }
        );
    }

    private setAdminStatus() {
        this.user = this._sharedEventsService.getUser();

        if (this.user && this.user.groups && this.user.groups.indexOf(Constants.adminUserGroup) !== -1) {
            return true;
        } else {
            return false;
        }
    }

    public toggleRequestModal() {
        this.accessModalVisible = !this.accessModalVisible;
    }

    private refreshFilesForFolder(folderName: string) {
        // this.getFilesByFolder(folderName);
        this.getAllFiles();
    }

    public logoutRoute() {
        this._router.navigateByUrl('login');
    }
}
