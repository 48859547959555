import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

import { FoldersService } from '../../services/folders.service';
import { FilesService } from '../../services/files.service';
import { SharedEventsService } from '../../services/sharedEvents.service';

import { Constants } from '../../../constants/constants';

import { Folder } from '../../../models/folder';
import { File } from '../../../models/file';

@Component({
    selector: 'folder-management-table',
    templateUrl: './folder-management-table.component.html',
    styleUrls: ['./folder-management-table.component.css']
})
export class FolderManagementTableComponent implements OnInit {
    @Input() public folders: Array<Folder>;
    @Input() public files: Array<File>;
    @Output() public filesNeedUpdate = new EventEmitter();
    @Output() public foldersNeedUpdate = new EventEmitter();
    public deleteModalVisible: boolean;
    public editModalVisible: boolean;
    public deleteFileModalVisible: boolean;
    private selectedFolder;
    private selectedFolderId;
    private selectedFile;
    private selectedFileId;
    private expandedFolderIds: Array<any> = [];
    private activeSelection: string;
    public notificationOptions = Constants.notificationOptions;

    constructor(
        private _foldersService: FoldersService,
        private _filesService: FilesService,
        private _notificationsService: NotificationsService,
        private _sharedEventsService: SharedEventsService
    ) { }

    public ngOnInit() {
    }

    private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
        switch (notificationType) {
            case 'success':
                this._notificationsService.success(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationsService.error(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    }

    private toggleEditModal(folderId?) {
        this.selectedFolderId = folderId ? folderId : null;
        if (this.selectedFolderId) {
            this.selectedFolder = this.folders.find(folder => {
                return folder.Id === this.selectedFolderId;
            });
            this.activeSelection = (this.selectedFolder && this.selectedFolder.ActiveYN === 'Y') ? 'Yes' : 'No';
        }
        this.editModalVisible = !this.editModalVisible;
    }

    private toggleDeleteModal(folderId?) {
        this.selectedFolderId = folderId ? folderId : null;
        if (this.selectedFolderId) {
            this.selectedFolder = this.folders.find(folder => {
                return folder.Id === this.selectedFolderId;
            });
        }
        this.deleteModalVisible = !this.deleteModalVisible;
    }

    public deleteFolder() {
        // api call for deleting folder
        this._foldersService.deleteFolder(this.selectedFolderId).subscribe(
            response => {
                this.foldersNeedUpdate.emit(true);
                this.createNotification('success', 'Delete Folder', `${this.selectedFolder.FolderName} successfully deleted`);
            },
            error => {
                console.log('delete folder error: ', error);
                this.createNotification(
                    'error', 'Delete Folder', 'An error has occurred, please try again or send an e-mail to cfowler@gsu.edu');
            });
        this.toggleDeleteModal();
    }

    public updateFolder(updatedFolder) {
        updatedFolder = {
            Id: updatedFolder.Id,
            FolderName: updatedFolder.FolderName,
            ActiveYN: updatedFolder.ActiveYN
        };

        // api call for updating folder
        this._foldersService.updateFolder(updatedFolder).subscribe(
            response => {
                this.activeSelection = null;
                this.foldersNeedUpdate.emit(true);
                this.createNotification('success', 'Update Folder', `${updatedFolder.FolderName} successfully updated`);
            },
            error => {
                console.log('update folder error:', error);
                this.createNotification(
                    'error', 'Update Folder', 'An error has occurred. Please try again or send an e-mail to cfowler13@gsu.edu');
            });

        if (this.editModalVisible) {
            this.toggleEditModal();
        }
    }

    public toggleFolderInfo(folderId) {
        const folderIndex = this.expandedFolderIds.indexOf(folderId);

        if (folderIndex < 0) {
            this.expandedFolderIds.push(folderId);
        } else {
            this.expandedFolderIds.splice(folderIndex, 1);
        }
    }

    private toggleDeleteFileModal(fileId?) {
        this.selectedFileId = fileId ? fileId : null;
        if (this.selectedFileId) {
            this.selectedFile = this.files.find(file => {
                return file.Id === this.selectedFileId;
            });
        }
        this.deleteFileModalVisible = !this.deleteFileModalVisible;
    }

    public deleteFile() {
        this._filesService.deleteFile(this.selectedFileId, this.selectedFile.Folder).subscribe(
            response => {
                this.filesNeedUpdate.emit(this.selectedFile.Folder);
                this.createNotification('success', 'Delete File', `${this.selectedFile.FileName} successfully deleted`);
                this._sharedEventsService.emitDataChange(false);
            },
            err => {
                console.log('delete file error: ', err.statusText);
                this.createNotification(
                    'error', 'Delete File', 'An error has occurred. Please try again or send an e-mail to cfowler13@gsu.edu');
                this._sharedEventsService.emitDataChange(false);
            }
        );

        this.toggleDeleteFileModal();
    }

    public updateActiveStatus(newStatus) {
        this.activeSelection = newStatus;
        this.selectedFolder.ActiveYN = (newStatus === 'Yes') ? 'Y' : 'N';
    }

    public viewFile(folderName, fileId) {
        const url = `/fileView/${folderName}/${fileId}`;
        window.open(url, '_blank');
    }
}
