import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../../services/login.service';
import { SharedEventsService } from '../../services/sharedEvents.service';

import { User } from '../../../models/user';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public username: string;
    public password: string;
    public loginErrorMessage: string;
    private user: User;

    constructor(
        private _loginService: LoginService,
        private _sharedEventsService: SharedEventsService,
        private _router: Router
    ) { }

    public ngOnInit() {
        this.user = this._sharedEventsService.getUser();

        if (this.user) {
            this._router.navigate(['/']);
        } else {
            // const sessionUser = localStorage.getItem('user');
            const sessionUser = this._loginService.getUserFromCookie();
            if (sessionUser) {
                this._router.navigate(['/']);
            }
        }
    }

    public login() {
        this._loginService.login(this.username, this.password).subscribe(
            res => {
                if (res.error) {
                    this.loginErrorMessage = res.error;
                    this.password = null;
                    // window.localStorage.removeItem('user');
                } else {
                    // set user info   + navigate to appropriate dashboard
                    this.user = res;
                    this._router.navigate(['']);
                    this._sharedEventsService.emitLoginChange(this.user);
                }
            },
            err => {
                console.log('login error: ', err);
            });
    }
}
