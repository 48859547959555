import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { SharedEventsService } from './sharedEvents.service';

import { Constants } from '../../constants/constants';

import { EmailRequestBody } from '../../models/emailRequestBody';
import { AccessRequest } from '../../models/accessRequest';

@Injectable()
export class EmailService {
    private apiUrl: string = Constants.emailAPIUrl;
    private statusChangeRequest: EmailRequestBody = new EmailRequestBody();
    private requestCreated: EmailRequestBody = new EmailRequestBody();

    constructor(
        private _http: Http,
        private _sharedEventsService: SharedEventsService
    ) {}

    public requestCreatedEmail(request: AccessRequest, folderNames: Array<string>) {
        this._sharedEventsService.emitDataChange(true);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Allow-Origin': '*'
        });

        this.requestCreated.messageType = 'AwardedProposalsRequest';
        this.requestCreated.toEmail = 'kpowellstout@gsu.edu';
        this.requestCreated.fullName = `${request.FirstName} ${request.LastName}`,
        this.requestCreated.folderName = folderNames.join(', ');
        this.requestCreated.requestStatus = 'Pending';

        return this._http.post(this.apiUrl, JSON.stringify(this.requestCreated), { headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public requestCreatedEmailConfirmation(request: AccessRequest, folderNames: Array<string>) {
        this._sharedEventsService.emitDataChange(true);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Allow-Origin': '*'
        });

        this.requestCreated.messageType = 'AwardedProposalsRequestConfirmation';
        this.requestCreated.toEmail = request.EmailAddress;
        this.requestCreated.fullName = `${request.FirstName} ${request.LastName}`,
        this.requestCreated.folderName = folderNames.join(', ');

        return this._http.post(this.apiUrl, JSON.stringify(this.requestCreated), { headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public statusUpdatedEmail(request: AccessRequest, newStatus: string, folderName: string) {
        this._sharedEventsService.emitDataChange(true);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Allow-Origin': '*'
        });

        this.statusChangeRequest.messageType = 'AwardedProposalsStatus';
        this.statusChangeRequest.folderName = folderName;
        this.statusChangeRequest.requestStatus = newStatus;
        this.statusChangeRequest.fullName = `${request.FirstName} ${request.LastName}`;
        this.statusChangeRequest.toEmail = request.EmailAddress;

        return this._http.post(this.apiUrl, JSON.stringify(this.statusChangeRequest), { headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }
}
