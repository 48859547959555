import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

import { Folder } from '../../../models/folder';
import { AccessRequest } from '../../../models/accessRequest';
import { File } from '../../../models/file';

@Component({
    selector: 'request-dashboard',
    templateUrl: './request-dashboard.component.html',
    styleUrls: ['./request-dashboard.component.css']
})
export class RequestDashboardComponent implements OnInit, OnChanges {
    @Input() public folders: Array<any>;
    @Input() public accessRequests: Array<AccessRequest>;
    @Input() public files: Array<File>;
    @Input() public userEmailAddress: string;
    @Output() public toggleCreateRequestModal = new EventEmitter();
    private accessFolderIds: Array<any> = [];
    public accessFolders: Array<any> = [];

    constructor() { }

    public ngOnInit() {
    }

    public ngOnChanges() {
        if (this.accessRequests) {
            this.accessRequests = this.accessRequests.filter(request => {
                return request.EmailAddress === this.userEmailAddress;
            });
        }

        if (this.folders) {
            // for non-admin dashboard, only show active folders!
            this.folders = this.folders.filter(folder => {
                return folder.ActiveYN === 'Y';
            });

            if (this.accessRequests) {
                this.accessFolderIds = [];
                this.accessFolders = [];
                // actual folder view list based on access requests 
                this.accessRequests.forEach(req => {
                    if (req.FolderAccess) {
                        req.FolderAccess.forEach(folderAccess => {
                            if (folderAccess.Access === 'Y' && moment(req.ExpirationDate).isAfter(moment())) {
                                this.accessFolderIds.push({
                                    FolderId: folderAccess.FolderId,
                                    ExpirationDate: req.ExpirationDate
                                });
                            }
                        });
                    }
                });

                if (this.accessFolderIds && this.accessFolderIds.length) {
                    // find and remove duplicates from list
                    this.accessFolderIds = this.accessFolderIds.map(id => {
                        return JSON.stringify(id);
                    }).filter((item, index, self) => {
                        return index === self.indexOf(item);
                    }).map(stringId => {
                        return JSON.parse(stringId);
                    });

                    this.accessFolderIds.forEach(accessObj => {
                        this.folders.forEach(folder => {
                            if (folder.Id === accessObj.FolderId) {
                                folder.ExpirationDate = accessObj.ExpirationDate;
                                folder.Files = [];
                                this.accessFolders.push(folder);
                            }
                        });
                    });

                    this.accessFolders.forEach(folder => {
                        this.files.forEach((file: any) => {
                            if (file.Folder === folder.FolderName) {
                                folder.Files.push(file);
                            }
                        });
                    });
                }
            }
        }
    }

    public toggleRequestModal() {
        this.toggleCreateRequestModal.emit(true);
    }

}
