import { Routes } from '@angular/router';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FileViewComponent } from './components/file-view/file-view.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

export const APP_ROUTES = [
    { path: '', component: DashboardComponent },
    { path: 'login', component: LoginComponent },
    { path: 'fileView/:folderName/:id', component: FileViewComponent },
    { path: '**', component: PageNotFoundComponent }
];
