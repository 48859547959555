import { EmailService } from './services/email.service';
import { FilesService } from './services/files.service';
import { FoldersService } from './services/folders.service';
import { LoginService } from './services/login.service';
import { RequestsService } from './services/requests.service';
import { SharedEventsService } from './services/sharedEvents.service';

export const APP_SERVICES = [
    EmailService,
    FilesService,
    FoldersService,
    LoginService,
    RequestsService,
    SharedEventsService
];