import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

import { FilesService } from './files.service';

import { User } from '../../models/user';
import { AccessRequest } from '../../models/accessRequest';


@Injectable()
export class SharedEventsService {
    public dataChange = new Subject<boolean>(); // data change = api call in progress
    public loginChange = new Subject<User>(); // updates user info at login/manages any changes
    public dataChangeEmitted = this.dataChange.asObservable();
    public loginChangeEmitted = this.loginChange.asObservable();
    private user: User;

    constructor() {}

    public emitDataChange(loading: boolean) {
        this.dataChange.next(loading);
    }

    public emitLoginChange(change: User) {
        this.user = change;
        this.loginChange.next(change);
    }

    public getUser() {
        if (this.user) {
            return this.user;
        } else {
            return null;
        }
    }
}
