import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs/Observable';

import { FilesService } from '../../services/files.service';
import { SharedEventsService } from '../../services/sharedEvents.service';

@Component({
    selector: 'access-folders-table',
    templateUrl: './access-folders-table.component.html',
    styleUrls: ['./access-folders-table.component.css']
})
export class AccessFoldersTableComponent implements OnInit {
    @Input() public accessFolders: Array<any>;
    private expandedFolderIds: Array<number> = [];

    constructor(
        private _filesService: FilesService,
        private _sharedEventsService: SharedEventsService
    ) { }

    public ngOnInit() {

    }

    public toggleFolderInfo(folderId) {
        const index = this.expandedFolderIds.indexOf(folderId);

        if (index >= 0) {
            this.expandedFolderIds.splice(index, 1);
        } else {
            this.expandedFolderIds.push(folderId);
        }
    }

    public viewFile(folderName, fileId) {
        const url = `/fileView/${folderName}/${fileId}`;
        window.open(url, '_blank');
    }
}
