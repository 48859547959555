export const Constants = {
    apiKey: 'a7bb5f65 - 6b5c-4d0e-91db-3613fc557ee5',
    loginAPIUrl: 'https://rsaccountsvc.rs.gsu.edu/api/Login.svc/login/',
    baseAPIUrl: 'https://researchportal-api-prod.azurewebsites.net/api/',
    fileAPIUrl: 'https://researchportal-api-prod.azurewebsites.net/file/',
    emailAPIUrl: 'https://rsemail.azurewebsites.net/api/ProposalSharing?code=lcBzeBu5aR8Tb8FX71VsisSH8TOYqrZt7K086La6pNQSRlxkmBdHvw==',
    accessRequestStatusList: {
        pending: 'Pending',
        approved: 'Approved',
        declined: 'Declined',
        expired: 'Expired'
    },
    adminUserGroup: 'Proposal Sharing Admin',
    // tslint:disable-next-line:max-line-length
    accessAgreementMessage: 'I understand that the information I am requesting is the intellectual property of the original author who has generously agreed to share this information for the purposes of education and training. By agreeing to access this information, you agree not to share or use inappropriately or illegally any information contained within the folder to which you are being granted access. You also agree not to make copies of any of this material in any manner.',
    notificationOptions: {
        position: ['top', 'right'],
        timeOut: 5000,
        showProgressBar: false
    }
};
