import { FolderAccess } from './folderAccess';

export class AccessRequest {
    Id: number;
    FirstName: string;
    LastName: string;
    EmailAddress: string;
    LastModified: string;
    AccessDate;
    ExpirationDate;
    FolderAccess;
}