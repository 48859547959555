import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { SuiModule } from 'ng2-semantic-ui';
import { FileUploadModule } from 'ng2-file-upload';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AppComponent } from './components/app/app.component';

import { APP_COMPONENTS } from './app.components';
import { APP_ROUTES } from './app.routes';
import { APP_SERVICES } from './app.services';

@NgModule({
    declarations: [
        ...APP_COMPONENTS
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpModule,
        HttpClientModule,
        FormsModule,
        SuiModule,
        FileUploadModule,
        PdfViewerModule,
        SimpleNotificationsModule.forRoot(),
        RouterModule.forRoot(APP_ROUTES),
    ],
    providers: [
        ...APP_SERVICES
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
