import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { Constants } from '../../../constants/constants';

import { AccessRequest } from '../../../models/accessRequest';
import { Folder } from '../../../models/folder';

@Component({
    selector: 'folder-access-requests-table',
    templateUrl: './folder-access-requests-table.component.html',
    styleUrls: ['./folder-access-requests-table.component.css']
})
export class FolderAccessRequestsTableComponent implements OnInit, OnChanges {
    @Input() public accessRequests: Array<AccessRequest>;
    @Input() public folders: Array<Folder>;
    public formattedRequests: Array<any> = [];
    private statusOptions = Constants.accessRequestStatusList;

    constructor() { }

    public ngOnInit() {
    }

    public ngOnChanges(changes) {
        if (this.accessRequests && this.folders) {
            this.formattedRequests = [];
            this.accessRequests.forEach(request => {
                if (request.FolderAccess) {
                    request.FolderAccess.forEach(folderRequest => {
                        let folder = this.folders.find(folder => {
                            return folder.Id === folderRequest.FolderId;
                        });
    
                        let req = {
                            Id: request.Id,
                            FirstName: request.FirstName,
                            LastName: request.LastName,
                            LastModified: request.LastModified,
                            AccessDate: request.AccessDate,
                            ExpirationDate: request.ExpirationDate,
                            EmailAddress: request.EmailAddress,
                            FolderId: folderRequest.FolderId,
                            FolderName: folder ? folder.FolderName : 'Folder Not Active',
                            Access: folderRequest.Access,
                            Status: null
                        };
    
                        req.Status = this.determineAccessStatus(req);
    
                        this.formattedRequests.push(req);
                    });
                }
            });
        }
    }

    private determineAccessStatus(request) {
        // check for expiration
        if (moment(request.ExpirationDate).isAfter(moment())) {
            // pending
            if (request.Access === '') {
                return this.statusOptions.pending;
            // approved
            } else if (request.Access === 'Y') {
                return this.statusOptions.approved;
            // declined
            } else if (request.Access === 'N') {
                return this.statusOptions.declined;
            }
        } else {
            return this.statusOptions.expired;
        }
    }
}
