import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import * as moment from 'moment';

import * as Cookies from 'js-cookie';

import { SharedEventsService } from './sharedEvents.service';

import { User } from '../../models/user';
import { Constants } from '../../constants/constants';

@Injectable()
export class LoginService {

    constructor(
        private _http: Http,
        private _sharedEventsService: SharedEventsService
    ) {}

    public login(username: string, password: string): Observable<any> {
        const headers = new Headers({ 'Content-Type': 'application/json' });
        const body = {
            username,
            password
        };

        return this._http.post(Constants.loginAPIUrl, JSON.stringify(body), { headers })
            .map(res => {
                const response = res.json();
                // this.setUserLocalStorage(response);
                this.setCookie(response);
                response.groups = response.groups ? response.groups.split(',') : response.groups;
                this._sharedEventsService.emitLoginChange(response);
                return response;
            })
            .catch(err => {
                return Observable.throw(err);
            });
    }

    private setUserLocalStorage(user) {
        user.date = moment();
        localStorage.setItem('user', JSON.stringify(user));
    }

    private setCookie(user: User) {
        // const expirationDate = moment().add(24, 'hours').toDate();

        //  this is for production, make sure line is uncommented so cookie expiration and domain work properly
        Cookies.set('user', JSON.stringify(user), { expires: 1, domain: '.gsu.edu' });

        //   this is for local development (for cookie to show up, comment out when committing to prod)
        // Cookies.set('user', JSON.stringify(user), { expires: 1, domain: '' });
    }

    public getUserFromCookie() {
        let user = Cookies.get('user');

        // const cookieSplit = document.cookie.split('=');

        // let user,
        //     userIndex;
        // if (cookieSplit.length > 1) {
        //     const userExists = cookieSplit.find((item, index) => {
        //         userIndex = index;
        //         return item === 'user';
        //     });

        //     if (userExists) {
        //         user = JSON.parse(cookieSplit[userIndex + 1]);
        //     } else {
        //         user = undefined;
        //     }
        // } else {
        //     user = undefined;
        // }

        if (user) {
            user = JSON.parse(user);
        } else {
            user = undefined;
        }

        return user;
    }

    public validateUserTime(loginDate) {
        const now = moment();
        const timeDifferenceInHours = now.diff(loginDate, 'hours');
        if (timeDifferenceInHours > 24) {
            return false;
        } else {
            return true;
        }
    }
}
