import { AccessFoldersTableComponent } from './components/access-folders-table/access-folders-table.component';
import { AccessManagementTableComponent } from './components/access-management-table/access-management-table.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { AppComponent } from './components/app/app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { FileViewComponent } from './components/file-view/file-view.component';
import { FolderAccessRequestsTableComponent } from './components/folder-access-requests-table/folder-access-requests-table.component';
import { FolderManagementTableComponent } from './components/folder-management-table/folder-management-table.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RequestDashboardComponent } from './components/request-dashboard/request-dashboard.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

export const APP_COMPONENTS = [
    AccessFoldersTableComponent,
    AccessManagementTableComponent,
    AdminDashboardComponent,
    AppComponent,
    DashboardComponent,
    DocumentViewerComponent,
    FileViewComponent,
    FolderAccessRequestsTableComponent,
    FolderManagementTableComponent,
    LoginComponent,
    PageNotFoundComponent,
    RequestDashboardComponent,
    ToolbarComponent
];
