import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';

import { RequestsService } from '../../services/requests.service';
import { SharedEventsService } from '../../services/sharedEvents.service';
import { EmailService } from '../../services/email.service';

import { Constants } from '../../../constants/constants';

import { AccessRequest } from '../../../models/accessRequest';
import { Folder } from '../../../models/folder';

@Component({
    selector: 'access-management-table',
    templateUrl: './access-management-table.component.html',
    styleUrls: ['./access-management-table.component.css']
})
export class AccessManagementTableComponent implements OnInit, OnChanges {
    @Input() public accessRequests: Array<AccessRequest>;
    @Input() public folders: Array<Folder>;
    @Input() public createModalOpen: boolean;
    @Input() public uploadModalOpen: boolean;
    @Output() public updateAccessRequests = new EventEmitter();
    public formattedRequests: Array<any> = [];
    private statusOptions = Constants.accessRequestStatusList;
    private statuses = ['Pending', 'Approved', 'Declined'];
    private expirationDateOptions = [
        '30 Days',
        '60 Days',
        '90 Days'
    ];
    public notificationOptions = Constants.notificationOptions;

    constructor(
        private _requestsService: RequestsService,
        private _sharedEventsService: SharedEventsService,
        private _emailService: EmailService,
        private _notificationsService: NotificationsService
    ) { }

    public ngOnInit() {
    }

    public ngOnChanges() {
        if (this.accessRequests && this.folders) {
            this.formattedRequests = [];
            this.accessRequests.forEach((request, index) => {
                if (request.FolderAccess && Array.isArray(request.FolderAccess)) {
                    this.formatFolderAccess(request, index);
                } else if (request.FolderAccess && !Array.isArray(request.FolderAccess)) {
                    request.FolderAccess = JSON.parse(request.FolderAccess);
                    this.formatFolderAccess(request, index);
                }
            });
        }
    }

    private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
        switch (notificationType) {
            case 'success':
                this._notificationsService.success(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationsService.error(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    }

    private formatFolderAccess(request, requestIndex) {
        request.FolderAccess.forEach(folderRequest => {
            let folder = this.folders.find(folder => {
                return folder.Id === folderRequest.FolderId;
            });

            let req = {
                Id: request.Id,
                FirstName: request.FirstName,
                LastName: request.LastName,
                LastModified: request.LastModified,
                AccessDate: request.AccessDate,
                ExpirationDate: request.ExpirationDate,
                EmailAddress: request.EmailAddress,
                FolderId: folderRequest.FolderId,
                FolderName: folder ? folder.FolderName : 'Folder No Longer Exists',
                Access: folderRequest.Access,
                Status: null,
                ExpirationRange: null
            };

            req.Status = this.determineAccessStatus(req);
            req.ExpirationRange = this.determineExpirationRange(req);
            req.AccessDate = (req.Status === 'Pending') ? moment().format() : req.AccessDate;
            req.ExpirationDate = this.updateFormattedRequestExpirationDate(req.ExpirationRange, req);
            this.formattedRequests.push(req);
            // this.formattedRequests = this.formattedRequests.filter(request => {
            //     return request.FolderName !== 'Folder No Longer Exists';
            // });
            this.accessRequests[requestIndex].AccessDate = (req.AccessDate !== this.accessRequests[requestIndex].AccessDate) ? req.AccessDate : this.accessRequests[requestIndex].AccessDate;
        });
    }

    private determineAccessStatus(request) {
        // check for expiration
        if (moment(request.ExpirationDate).isAfter(moment())) {
            // pending
            if (request.Access === '') {
                return this.statusOptions.pending;
            // approved
            } else if (request.Access === 'Y') {
                return this.statusOptions.approved;
            // declined
            } else if (request.Access === 'N') {
                return this.statusOptions.declined;
            }
        } else {
            return this.statusOptions.expired;
        }
    }

    private updateStatus(status, request) {
        // update status in dropdown
        this.formattedRequests.forEach((req, index) => {
            if (req.Id === request.Id && request.FolderName === req.FolderName) {
                this.formattedRequests[index].Status = status;
            }
        });
        // make API call to update request!
        let updatedRequest = this.accessRequests.find(r => {
            return r.Id === request.Id;
        });

        if (updatedRequest) {
            updatedRequest.LastModified = moment().toString();
            updatedRequest.ExpirationDate = this.updateFormattedRequestExpirationDate(request.ExpirationRange, request);
            switch (status) {
                case 'Pending':
                    updatedRequest.FolderAccess = updatedRequest.FolderAccess.map(folder => {
                        if (folder.FolderId === request.FolderId) {
                            return {
                                FolderId: folder.FolderId,
                                Access: ''
                            };
                        } else {
                            return folder;
                        }
                    });
                    this.updateRequest(updatedRequest, status, request.FolderId);
                    break;
                case 'Approved':
                    updatedRequest.FolderAccess = updatedRequest.FolderAccess.map(folder => {
                        if (folder.FolderId === request.FolderId) {
                            return {
                                FolderId: folder.FolderId,
                                Access: 'Y'
                            };
                        } else {
                            return folder;
                        }
                    });
                    // updatedRequest.AccessDate = moment().format('MM-DD-YYYY'); -- do we need to update access date here?
                    this.updateRequest(updatedRequest, status, request.FolderId);
                    break;
                case 'Declined':
                    updatedRequest.FolderAccess = updatedRequest.FolderAccess.map(folder => {
                        if (folder.FolderId === request.FolderId) {
                            return {
                                FolderId: folder.FolderId,
                                Access: 'N'
                            };
                        } else {
                            return folder;
                        }
                    });
                    this.updateRequest(updatedRequest, status, request.FolderId);
                    break;
                case 'Expired':
                    updatedRequest.FolderAccess = updatedRequest.FolderAccess.map(folder => {
                        if (folder.FolderId === request.FolderId) {
                            return {
                                FolderId: folder.FolderId,
                                Access: 'N'
                            };
                        } else {
                            return folder;
                        }
                    });
                    this.updateRequest(updatedRequest, status, request.FolderId);
                    break;
            }
        }
    }

    private updateRequest(request, status?, folderId?) {
        request.FolderAccess = JSON.stringify(request.FolderAccess);
        this._requestsService.updateRequest(request).subscribe(
            response => {
                // email notification!!
                if (status && folderId) {
                    this.createNotification('success', 'Request Updated', `Status has been updated to ${status}. ${request.FirstName} ${request.LastName} has been notified by e-mail.`);
                    this.sendEmailNotification(request, status, folderId);
                } else {
                    this.createNotification('success', 'Length of Access Updated', 'Expiration date successfully updated');
                }
                // update list!
                this.updateAccessRequests.emit(true);
            },
            error => {
                console.log('update request error: ', error);
                this.createNotification('error', 'Request Error', 'An error has occurred, please try again or send an e-mail to cfowler13@gsu.edu');
            }
        );
    }

    private sendEmailNotification(request, status, folderId) {
        let folder = this.folders.find(fol => {
            return fol.Id === folderId;
        });

        if (folder) {
            this._emailService.statusUpdatedEmail(request, status, folder.FolderName).subscribe(
                res => {
                    console.log('status email', res);
                },
                err => {
                    console.log('status email error: ', err);
                }
            );
        }
    }

    private deleteRequest(request) {
        this._requestsService.deleteRequest(request.Id).subscribe(
            response => {
                this.updateAccessRequests.emit(true);
            },
            error => {
                console.log('delete request error:', error);
            }
        );
    }

    private determineExpirationRange(request) {
        let difference = moment(request.ExpirationDate).diff(moment(request.AccessDate), 'days');
        switch(Math.ceil(difference/10)*10) {
            case 30:
                return '30 Days';
            case 40:
                return '30 Days';
            case 60:
                return '60 Days';
            case 70:
                return '60 Days';
            case 90:
                return '90 Days';
            case 100: 
                return '90 Days';
            default:
                return null;
        }
    }

    private updateExpirationDate(newRange, request) {
        let expirationDate;
        switch (newRange) {
            case '30 Days':
                expirationDate = moment(request.AccessDate).add(30, 'day').format('MM-DD-YYYY');
                break;
            case '60 Days':
                expirationDate = moment(request.AccessDate).add(60, 'day').format('MM-DD-YYYY');
                break;
            case '90 Days':
                expirationDate = moment(request.AccessDate).add(90, 'day').format('MM-DD-YYYY');
                break;
            default:
                break;
        }

        if (newRange !== request.ExpirationRange) {
            // update original request with new expiration date + make api call
            this.accessRequests.forEach((accessRequest, index) => {
                if (accessRequest.Id === request.Id) {
                    this.accessRequests[index].ExpirationDate = expirationDate;
                    this.accessRequests[index].LastModified = moment().toString();
                    this.updateRequest(this.accessRequests[index]);
                }
            });
        } 
    }

    private updateFormattedRequestExpirationDate(range, req) {
        switch(range) {
            case '30 Days':
                return moment(req.AccessDate).add(30, 'day').format('MM-DD-YYYY');
            case '60 Days':
                return moment(req.AccessDate).add(60, 'day').format('MM-DD-YYYY');
            case '90 Days':
                return moment(req.AccessDate).add(90, 'day').format('MM-DD-YYYY');
            default: 
                break;
        }
    }
}

