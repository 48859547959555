import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as Cookies from 'js-cookie';

import { NotificationsService } from 'angular2-notifications';

import { RequestsService } from '../../services/requests.service';
import { SharedEventsService } from '../../services/sharedEvents.service';
import { EmailService } from '../../services/email.service';

import { Constants } from '../../../constants/constants';

import { Folder } from '../../../models/folder';
import { AccessRequest } from '../../../models/accessRequest';
import { User } from '../../../models/user';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnChanges {
    @Input() public admin: boolean;
    @Input() public folders: Array<Folder>;
    @Input() public accessModalVisible: boolean;
    @Input() public userInfo: User;
    @Output() public updateAccessRequests = new EventEmitter();
    @Output() public toggleAccessModal = new EventEmitter();
    @Output() public goToLogin = new EventEmitter();
    public signOutModalVisible: boolean;
    private selectedFolders: Array<any> = [];
    private request = new AccessRequest();
    private dateRange: string;
    private expirationOptions: Array<string> = [
        '30 Days',
        '60 Days',
        '90 Days'
    ];
    private agreementMessage = Constants.accessAgreementMessage;
    private acceptedAgreement: boolean;
    private errorMessage: string;
    public notificationOptions = Constants.notificationOptions;
    public userName: string;

    constructor(
        private _requestsService: RequestsService,
        private _sharedEventsService: SharedEventsService,
        private _emailService: EmailService,
        private _notificationsService: NotificationsService
    ) { }

    public ngOnInit() {
        if (this.userInfo) {
            this.userName = this.userInfo.firstname;
        }
    }

    public ngOnChanges() {
        if (!this.userName && this.userInfo) {
            this.userName = this.userInfo.firstname;
        }

        if (this.folders && !this.admin) {
            this.folders = this.folders.filter(folder => {
                return folder.ActiveYN === 'Y';
            });
        }

        if (this.accessModalVisible) {
            const today = moment();
            // go ahead and set to 30
            this.dateRange = '30 Days';
            this.request.AccessDate = today.format('MM-DD-YYYY');
            this.request.ExpirationDate = today.add(30, 'day').format('MM-DD-YYYY');
        }
    }

    private toggleModal() {
        this.toggleAccessModal.emit(true);
    }

    private submitRequest() {
        this.request.FirstName = this.userInfo.firstname;
        this.request.LastName = this.userInfo.lastname;
        this.request.EmailAddress = this.userInfo.email;

        if (!this.selectedFolders.length) {
            this.errorMessage = 'Please select at least one folder';
            this.acceptedAgreement = false;
            return;
        }

        if (!this.request.ExpirationDate) {
            this.errorMessage = 'Please select an option for the length of access';
            this.acceptedAgreement = false;
            return;
        }

        const folderAccess = this.selectedFolders.map(folderName => {
            const folderInfo = this.folders.find(folder => {
                return folder.FolderName === folderName;
            });

            if (folderInfo) {
                return {
                    FolderId: folderInfo.Id,
                    Access: ''
                };
            }
        });

        this.request.AccessDate = moment(this.request.AccessDate).format('MM-DD-YYYY');

        if (folderAccess.length > 1) {
            // create new requests array with each type in folder access array
            const requests = folderAccess.map(access => {
                const request = new AccessRequest();

                request.FirstName = this.request.FirstName;
                request.LastName = this.request.LastName;
                request.AccessDate = this.request.AccessDate;
                request.EmailAddress = this.request.EmailAddress;
                request.ExpirationDate = this.request.ExpirationDate;
                request.FolderAccess = JSON.stringify([access]);
                return request;
            });

            requests.forEach(request => {
                this.submitCreatedAccessRequest(request);
            });

            this.toggleModal();
        } else {
            // use current request with folder access array
            this.request.FolderAccess = JSON.stringify(folderAccess);
            this.submitCreatedAccessRequest(this.request);
            this.toggleModal();
        }
    }

    private submitCreatedAccessRequest(request: AccessRequest) {
        this._requestsService.createRequest(request).subscribe(
            response => {
                this.sendEmailNotification(request);

            },
            error => {
                console.log('create request error: ', error);
                this._notificationsService.error('Submit Request', 'An error has occurred. Please try again or send an e-mail to researchsolutions@gsu.edu');
            });
    }

    private sendEmailNotification(request: AccessRequest) {
        const folderNames = [];

        request.FolderAccess = JSON.parse(request.FolderAccess);
        const folderIds = request.FolderAccess.map(fAccess => {
            return fAccess.FolderId;
        });

        folderIds.forEach(id => {
            this.folders.forEach(folder => {
                if (id === folder.Id) {
                    folderNames.push(folder.FolderName);
                }
            });
        });
        // Notify approver
        this._emailService.requestCreatedEmail(request, folderNames).subscribe(
            res => {
                console.log('request created email', res);

                // Notify requestor
                this._emailService.requestCreatedEmailConfirmation(request, folderNames).subscribe(
                    res2 => {
                        console.log('request created confirmation email', res2);

                        this._notificationsService.success('Submit Request', 'Request successfully submitted');
                        this.request = new AccessRequest();
                        this.selectedFolders = [];
                        this.dateRange = '';
                        this.errorMessage = '';
                        this.acceptedAgreement = false;
                        // refresh UI!
                        this.updateAccessRequests.emit(true);
                    },
                    err => {
                        console.log('request created confirmation email error: ', err);
                    }
                );
            },
            err => {
                console.log('request created email error: ', err);
            }
        );

    }

    private updateExpirationDate(dateOption) {
        this.request.AccessDate = moment();
        this.dateRange = dateOption;

        switch (dateOption) {
            case '30 Days':
                this.request.ExpirationDate = moment(this.request.AccessDate).add(30, 'day').format('MM-DD-YYYY');
                this.request.AccessDate = this.request.AccessDate.format('MM-DD-YYYY');
                break;
            case '60 Days':
                this.request.ExpirationDate = moment(this.request.AccessDate).add(60, 'day').format('MM-DD-YYYY');
                this.request.AccessDate = this.request.AccessDate.format('MM-DD-YYYY');
                break;
            case '90 Days':
                this.request.ExpirationDate = moment(this.request.AccessDate).add(90, 'day').format('MM-DD-YYYY');
                this.request.AccessDate = this.request.AccessDate.format('MM-DD-YYYY');
                break;
            default:
                break;
        }
    }

    private toggleSignOutModal() {
        this.signOutModalVisible = !this.signOutModalVisible;
    }

    public signOut() {
        // this.toggleSignOutModal();
        // for local dev
        // Cookies.remove('user', { domain: '' });
        // for prod
        Cookies.remove('user', { domain: '.gsu.edu' });
        this._sharedEventsService.emitLoginChange(null);
        this.goToLogin.emit(true);
    }
}
