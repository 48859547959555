import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { SharedEventsService } from '../../services/sharedEvents.service';
import { LoginService } from '../../services/login.service';

import { User } from '../../../models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    private loggedIn: boolean;
    private userInfo: User;
    private fileViewOpen: boolean;
    public appLoading: boolean;

    constructor(
        private _router: Router,
        private _sharedEventsService: SharedEventsService,
        private _loginService: LoginService
    ) { }

    public ngOnInit() {
        this.appLoading = true;

        // redirect to https if needed
        if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
            window.location.protocol = 'https:';
            //   this.appLoading = false;
        } else {
            this.appLoading = false;
        }

        this._sharedEventsService.loginChangeEmitted.subscribe(
            change => {
                this.userInfo = change;
                this.loggedIn = (this.userInfo && this.userInfo.username) ? true : false;
            }
        );

        this.checkStorageForUser();


        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.fileViewOpen = (event.url.indexOf('fileView') >= 0) ? true : false;

                // if (!this.loggedIn && !this.fileViewOpen) {
                if (!this.loggedIn) {
                    this._router.navigate(['login']);
                    this.appLoading = false;
                } else {
                    this.appLoading = false;
                }
            }
        });
    }

    private checkStorageForUser() {
        console.log('check storage for user');
        // const storedUser = JSON.parse(localStorage.getItem('user'));
        const storedUser = this._loginService.getUserFromCookie();

        if (storedUser) {
            storedUser.groups = storedUser.groups ? storedUser.groups.split(',') : storedUser.groups;
            this._sharedEventsService.emitLoginChange(storedUser);
        }
    }
}
