import { Injectable } from '@angular/core';
// import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';

import { FoldersService} from './folders.service';
import { SharedEventsService } from './sharedEvents.service';

import { Constants } from '../../constants/constants';
import { File } from '../../models/file';

@Injectable()
export class FilesService {
    private url = Constants.baseAPIUrl;
    private apiKey = Constants.apiKey;
    // private sampleFiles: Array<File> = [
    //     {
    //         Id: 1,
    //         FileName: 'Practice File',
    //         DateUploaded: moment().format('MM-DD-YYYY'),
    //         DocumentAuthor: 'Cori Fowler',
    //         DocumentAuthorEmail: 'cfowler13@gsu.edu',
    //         Folder: 'Test folder 6',
    //         ContentType: null
    //     },
    //     {
    //         Id: 2,
    //         FileName: 'Practice File 2',
    //         DateUploaded: moment().format('MM-DD-YYYY'),
    //         DocumentAuthor: 'Cori Fowler',
    //         DocumentAuthorEmail: 'cfowler13@gsu.edu',
    //         Folder: 'Test folder 6',
    //         ContentType: null
    //     }
    // ];
    private headers = new HttpHeaders ({
        'API_KEY': this.apiKey
    });

    constructor(
        private _http: HttpClient,
        private _foldersService: FoldersService,
        private _sharedEventsService: SharedEventsService
    ) {}

    public getFiles(folderName: string): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.get(`${this.url}pstFiles?FolderName=${folderName}`, { headers: this.headers });
    }

    public getAllFiles(): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.get(`${this.url}pstFiles`, { headers: this.headers });
    }

    public getSampleFiles(): Array<File> {
        // return this.sampleFiles;
        return [];
    }

    public getFileFromApi(fileId) {
        const url = `${this.url}pstFiles?fileId=${fileId}`;
        return this._http.get(url, { headers: this.headers });
    }

    public getFile(fileId) {
        // this just navigates directly to file 
        const url = `${Constants.fileAPIUrl}getFile/${fileId}`;
        return url;
    }

    public deleteFile(fileId, folderName): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.delete(`${this.url}pstFiles/${fileId}`, { headers: this.headers});
    }
}
