import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { FoldersService } from './folders.service';
import { SharedEventsService } from './sharedEvents.service';

import { Constants } from '../../constants/constants';

@Injectable()
export class RequestsService {
    private baseUrl: string = Constants.baseAPIUrl + 'pstAccess/';
    private apiKey = Constants.apiKey;

    private headers = new Headers({
        'API_KEY': this.apiKey
    });

    constructor(
        private _http: Http,
        private _foldersService: FoldersService,
        private _sharedEventsService: SharedEventsService
    ) {}

    public getRequests(): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.get(this.baseUrl, { headers: this.headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public getRequestById(requestId): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.get(`${this.baseUrl}${requestId}`, { headers: this.headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public createRequest(newRequest): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        const headers = new Headers({
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json'
            'Accept': 'application/json;odata=verbose',
            'Content-Type': 'application/json;odata=verbose',
            'API_KEY': this.apiKey
        });
        return this._http.post(this.baseUrl, JSON.stringify(newRequest), { headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public updateRequest(request): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'API_KEY': this.apiKey
        });
        return this._http.put(`${this.baseUrl}${request.Id}`, JSON.stringify(request), { headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public deleteRequest(requestId): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.delete(`${this.baseUrl}${requestId}`, { headers: this.headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }
}
