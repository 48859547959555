import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilesService } from '../../services/files.service';
import { SharedEventsService } from '../../services/sharedEvents.service';

import { File } from '../../../models/file';

@Component({
    selector: 'file-view',
    templateUrl: './file-view.component.html',
    styleUrls: ['./file-view.component.css']
})
export class FileViewComponent implements OnInit {
    private fileId;
    private folderName;
    public fileName: string;
    private pdfUrl: any;
    public pdfZoom = 1.5;
    public errorMessage: string;
    public pdfLoading: boolean;

    constructor(
        private _route: ActivatedRoute,
        private _filesService: FilesService,
        private _sharedEventsService: SharedEventsService
    ) { }

    public ngOnInit() {
        this.togglePdfLoader(true);
        this._route.params.subscribe(param => {
            this.fileId = Number(param['id']);
            this.folderName = param['folderName'];
            // this.pdfUrl = this._filesService.getFile(this.fileId);
            this.getFileFromApi(this.fileId);
            if (this.fileId && this.folderName) {
                this.getFolder(this.folderName);
            } else {
                this.errorMessage = 'An error has occurred. Please close this window and try again.';
            }
        });
    }

    private getFileFromApi(fileId) {
        // let url = '';
        this._filesService.getFileFromApi(fileId).subscribe(
            (response: any) => {
                this.pdfUrl = this.base64ToArrayBuffer(response);

                // let file = new Blob([response], { type: 'application/pdf'});
                // const file = new Uint8Array(response);
                // url = window.URL.createObjectURL(file);
                // this.pdfUrl = url;
                // this.pdfUrl = file;
                // this.togglePdfLoader(false);
            },
            err => {
                console.log('get file from api error: ', err);
                // this.togglePdfLoader(false);
            }
        );
    }

    private base64ToArrayBuffer(base64) {
        const binaryString = window.atob(base64);
        const length = binaryString.length;

        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    private getFolder(folderName) {
        this._filesService.getFiles(folderName).subscribe(
            (files: Array<any>) => {
                const fileMatch = files.find(file => {
                    return file.Id === this.fileId;
                });
                
                this.fileName = fileMatch ? fileMatch.FileName : '';
                this._sharedEventsService.emitDataChange(false);
            },
            err => {
                console.log('get folder error: ', err);
                this._sharedEventsService.emitDataChange(false);
            }
        );
    }

    public closeFile() {
        window.close();
    }

    public updateZoomValue(increaseOrDecrease: string) {
        if (increaseOrDecrease === 'increase') {
            this.pdfZoom = this.pdfZoom + 0.25;
        } else {
            this.pdfZoom = this.pdfZoom - 0.25;
        }
    }

    private togglePdfLoader(loading: boolean) {
        this.pdfLoading = loading;
    }

}
