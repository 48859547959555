import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SuiTabsModule } from 'ng2-semantic-ui';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import * as moment from 'moment';
import { NotificationsService } from 'angular2-notifications';

import { FoldersService } from '../../services/folders.service';

import { Constants } from '../../../constants/constants';
import { File } from '../../../models/file';
import { User } from '../../../models/user';

@Component({
    selector: 'admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit, OnChanges {
    @Input() public folders;
    @Input() public accessRequests;
    @Input() public files;
    @Input() public userInfo: User;
    @Output() public updateFolders = new EventEmitter();
    @Output() public updateAccessRequests = new EventEmitter();
    @Output() public updateFilesForFolder = new EventEmitter();
    public createFolderModalVisible: boolean;
    public uploadFileModalVisible: boolean;
    private activeSelection: string;
    private newFolderName: string;
    private file = new File();
    private fileApiUrl = `${Constants.baseAPIUrl}pstFilesUpload/uploadfile`;
    // private fileApiUrl = `${Constants.baseAPIUrl}pstFiles/uploadfile`;
    //   private fileApiUrl = 'https://sarah-dev-api.azurewebsites.net/file/uploadfile';
    private fileUploader = new FileUploader({
        url: this.fileApiUrl,
        itemAlias: 'file',
        headers: [
            {
                name: 'API_KEY',
                value: Constants.apiKey
            }
        ]
    });
    public formattedFoldersList;
    private folderNames: Array<string>;
    private createFolderErrorMessage: string;
    public notificationOptions = Constants.notificationOptions;

    constructor(
        private _foldersService: FoldersService,
        private _notificationsService: NotificationsService
    ) { }

    public ngOnInit() {
        if (this.userInfo) {
            this.file.documentAuthor = `${this.userInfo.firstname} ${this.userInfo.lastname}`;
            this.file.documentAuthorEmail = this.userInfo.email;
        }

        this.fileUploader.onBuildItemForm = (fileItem, form) => {
          form.append('documentAuthor', this.file.documentAuthor);
          form.append('documentAuthorEmail', this.file.documentAuthorEmail);
          form.append('folder', this.file.folder);
        };

        this.fileUploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            // emit an event to retrieve files for updated folder
            if (status === 200) {
                this.updateFilesList(this.file.folder);
                const fileName = item.file.name;

                this.createNotification('success', 'Upload File', `${fileName} successfully uploaded`);
                this.file.folder = '';
            } else {
                this.createNotification(
                    'error', 'Upload File', 'An error has occurred. Please try again or send an e-mail to cfowler13@gsu.edu');
            }
        };
    }

    public ngOnChanges(changes) {
        if (this.files && this.folders && (changes.files || changes.folders)) {
            this.formatFoldersList();

            this.folderNames = this.folders.map(folder => {
                return folder.FolderName;
            });
        }
    }

    private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
        switch (notificationType) {
            case 'success':
                this._notificationsService.success(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationsService.error(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    }

    private formatFoldersList() {
        this.formattedFoldersList = this.folders.map(folder => {
            return {
                Id: folder.Id,
                FolderName: folder.FolderName,
                ActiveYN: folder.ActiveYN,
                Files: []
            };
        });

        this.pushFilesToFolders();
    }

    private pushFilesToFolders() {
        this.files.forEach(file => {
            this.formattedFoldersList.forEach((folder, index) => {
                if (file.Folder === folder.FolderName) {
                    this.formattedFoldersList[index].Files.push(file);
                }
            });
        });
    }

    public toggleCreateFolderModal() {
        this.createFolderModalVisible = !this.createFolderModalVisible;
    }

    public createFolder() {
        const folderNameIndex = this.folderNames.indexOf(this.newFolderName);

        if (folderNameIndex < 0) {
            const activeYN = this.activeSelection === 'Yes' ? 'Y' : 'N';
            const newFolder = {
                folderName: this.newFolderName,
                activeYN
            };

            this._foldersService.createFolder(newFolder).subscribe(
                res => {
                    this.updateFolders.emit(true);
                    this.createNotification('success', 'Create Folder', `${newFolder.folderName} successfully created`);
                    this.newFolderName = '';
                    this.activeSelection = '';
                },
                err => {
                    console.log('create folder error: ', err);
                    this.createNotification(
                        'error', 'Create Folder', 'An error has occurred, please try again or send an e-mail to cfowler13@gsu.edu');
                }
            );

            this.toggleCreateFolderModal();
        } else {
            this.createFolderErrorMessage = 'A folder already exists with that name';
        }
    }

    public updateFoldersList() {
        this.updateFolders.emit(true);
    }

    public refreshAccessRequests() {
        this.updateAccessRequests.emit(true);
    }

    public toggleUploadFileModal() {
        this.uploadFileModalVisible = !this.uploadFileModalVisible;

        if (!this.uploadFileModalVisible) {
            this.file.folder = undefined;
        }
    }

    public uploadFile() {
        this.file.dateUploaded = moment().format('MM-DD-YYYY');
        this.fileUploader.uploadAll();
        this.toggleUploadFileModal();
    }

    public updateFilesList(folderName: string) {
        this.updateFilesForFolder.emit(folderName);
    }
}
