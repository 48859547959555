import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { SharedEventsService } from './sharedEvents.service';

import { Constants } from '../../constants/constants';

@Injectable()
export class FoldersService {
    private apiUrl = Constants.baseAPIUrl + 'pstFolders/';
    private apiKey = Constants.apiKey;
    private headers = new Headers({
        'API_KEY': this.apiKey
    });

    constructor(
        private _http: Http,
        private _sharedEventsService: SharedEventsService
    ) {}

    public getFolders(): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.get(this.apiUrl, { headers: this.headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public getFolder(folderId): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.get(`${this.apiUrl}${folderId}`, { headers: this.headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public createFolder(newFolder): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'API_KEY': this.apiKey
        });
        return this._http.post(this.apiUrl, JSON.stringify(newFolder), { headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public updateFolder(updatedFolder): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        const headers = new Headers({
            'Content-Type': 'application/json',
            'API_KEY': this.apiKey
        });
        return this._http.put(`${this.apiUrl}${updatedFolder.Id}`, JSON.stringify(updatedFolder), { headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }

    public deleteFolder(folderId): Observable<any> {
        this._sharedEventsService.emitDataChange(true);
        return this._http.delete(`${this.apiUrl}${folderId}`, { headers: this.headers })
            .map(response => {
                this._sharedEventsService.emitDataChange(false);
                return response.json();
            })
            .catch(error => {
                this._sharedEventsService.emitDataChange(false);
                return Observable.throw(error);
            });
    }
}